export class AnnouncementScreen {
    private container: HTMLElement | null = null;

    constructor() {}

    public async mount(container: HTMLElement) {
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen bg-gradient-to-b from-gray-900 via-gray-900 to-purple-900/20';

        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6';
        container.appendChild(mainContent);

        // Title Section - enhanced but keeping core style
        const titleContainer = document.createElement('div');
        titleContainer.className = `
            flex
            flex-col
            items-center
            justify-center
            gap-4
            text-center
            mt-12
            mb-4
            px-4
            w-full
        `;

        const title = document.createElement('p');
        title.textContent = 'PLATO5 v2';
        title.className = `
            text-transparent bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
            text-3xl sm:text-4xl lg:text-5xl
            font-extrabold
            font-['Inter']
            leading-relaxed
            max-w-3xl
            animate-gradient
            tracking-tight
            animate-fade-in
        `;

        const subtitle = document.createElement('p');
        subtitle.className = `
            text-white/70
            text-lg
            mt-2
            font-light
            tracking-wide
        `;
        subtitle.textContent = 'Coming Soon';

        titleContainer.appendChild(title);
        titleContainer.appendChild(subtitle);
        mainContent.appendChild(titleContainer);

        // Launch date - slightly enhanced
        const launchDate = document.createElement('div');
        launchDate.className = `
            text-white/50 
            text-center
            mt-2 mb-6 
            px-4
            text-lg
            font-light
        `;
        launchDate.textContent = 'Launching April 2025';
        mainContent.appendChild(launchDate);

        // Main Message Box - enhanced styling while keeping core design
        const messageBox = document.createElement('div');
        messageBox.className = `
            bg-white/5 
            backdrop-blur-lg 
            rounded-lg 
            p-8
            mb-8
            text-white/80
            border border-white/10
        `;

        const message = document.createElement('p');
        message.className = 'leading-relaxed mb-8 text-lg';
        message.textContent = "We're taking a brief pause to refocus PLATO5 on what matters most - creating meaningful connections that evolve into real friendships.";
        
        const updatesList = document.createElement('div');
        updatesList.className = 'space-y-4 mt-6';
        
        const updates = [
            'Streamlined matching system with 24-hour refresh cycles',
            'Direct entry into Nanos for immediate connection',
            'Enhanced focus on turning online connections into IRL friendships',
            'Refined Nearby Events feature for local meetups',
            '12-hour join window for more engaged group formation'
        ];

        updates.forEach(update => {
            const updateItem = document.createElement('div');
            updateItem.className = 'flex items-center gap-3';
            updateItem.innerHTML = `
                <i class="fas fa-check text-emerald-400"></i>
                <span class="text-white/80">${update}</span>
            `;
            updatesList.appendChild(updateItem);
        });

        messageBox.appendChild(message);
        messageBox.appendChild(updatesList);
        mainContent.appendChild(messageBox);

        // Footer Note - keeping it clean
        const footerNote = document.createElement('p');
        footerNote.className = 'text-white/50 text-sm text-center mt-8';
        footerNote.textContent = 'Current users will be notified when the platform is back online.';
        mainContent.appendChild(footerNote);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        this.container = null;
    }
}